@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url("../fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"), url("../fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url("../fonts/Gilroy-Light.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url("../fonts/Gilroy-Regular.ttf") format("truetype");
  font-weight: 600;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
}
p {
  font-family: "Gilroy-Regular", sans-serif;
}

label {
  font-family: "Gilroy-Medium", sans-serif;
}
label,
button {
  cursor: pointer;
  background: transparent;
}
textarea {
  width: 500px;
}

input,
button,
textarea {
  border: none;
  outline: none;
}

ul,
li {
  list-style: none;
}
.espace-btn {
  font-family: "Gilroy-Bold", sans-serif;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.245867);
  border-radius: 26.5px;
}

body::-webkit-scrollbar {
  width: 0.45em;
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #2a263d;
}
.eNdpiB {
  box-sizing: border-box;
  padding: 0;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  border: none;
  margin: 5px;
  background-color: #0390ff;
  font-size: 1.3em;
  content: "";
  height: 10px;
  width: 10px;
  box-shadow: 0 0 1px 3px #0390ff;
  border-radius: 50%;
  outline: none;
}
a {
  font-family: "Gilroy-Medium", sans-serif;
  color: #000;
}
.title-component {
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: bold;
}

.icon {
  margin: 0 0.8em;
}
.logo-footer {
  margin-bottom: 0.8em;
}
.typography-footer {
  color: #183796;
  text-align: center;
}
.title {
  padding-bottom: 0.5em;
  font-size: 20px;
}

.input-agence {
  flex: 0.9 auto;
  padding-left: 2em;
  font-size: 1em;
}
.btn-agence {
  border-radius: 30px;
  background: #183796;
  color: white;
  border: none;
  padding: 4px 14px;
  font-size: 14px;
  margin: 0.9em 0;
  font-family: "Gilroy-Regular", sans-serif;
}
.one {
  margin-right: 0.9em;
}
.map {
  height: 95%;
}

.radio {
  height: 22px;
  width: 22px;
}
textarea {
  width: 570px;
  height: 120px;
  background: none;
  border-radius: 22.5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1em;
  padding-left: 1em;
}
.grid-online {
  height: 460px;
  margin-top: 9.5em;
  margin-bottom: 4em;
  position: relative;
}
.grid-online-img {
  position: absolute;
  right: 0;
  bottom: -90%;
  z-index: -3;
}
.grid-online-right {
  background: #1bdebb;
  height: 340px;
  color: #fff;
  padding-left: 3em;
}

.grid-online-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2em;
  color: #153398;
}
.grid-online-text > p {
  padding-bottom: 3em;
  padding-top: 0.8em;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 1em;
  line-height: 25px;
  font-size: 20px;
  text-align: justify;
  text-justify: inter-word;
}
.grid-online-text > h2 {
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 2.5em;
}
.btn-element {
  margin-top: 0.8em;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.btn-element > button {
  padding: 12px 20px;
  color: #fff;
  background: linear-gradient(90deg, #13cdb0 0%, #00c9a8 100%);
  box-shadow: 0px 5px 5px rgba(3, 8, 40, 0.152797);
  border-radius: 31px;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 20px;
  line-height: 23.44px;
}
.btn-element > button + button {
  margin-left: 0.8em;
}

.grid-devis-left {
  background: #1bdebb;
  display: flex;
  padding-top: 2em;
  padding-bottom: 2em;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  padding-left: 3em;
  position: relative;
}
.ligne {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Gilroy-Bold", sans-serif;
}

.grid-devis-left-det-2 {
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.grid-devis-left-det-2 > img {
  height: 450px;
  position: absolute;
  top: -28%;
  right: -20%;
  z-index: 2000;
  object-fit: fill;
  overflow: hidden;
}

@media screen and (max-width: 1187px) {
  .grid-devis-left {
    height: auto;
  }
}
.img-contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 2em;
}
.img-ss {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.img-ss > img {
  height: 100%;
}

@media screen and (max-width: 1144px) {
  .grid-devis-left {
    height: auto;
  }
  .img-contact-form > img {
    height: 100%;
  }
}
@media screen and (max-width: 900px) {
  .grid-devis-left {
    width: 100vw;
  }
}
.grid-devis-left-det {
  padding-left: 4.5em;
  margin: 0;
}
.grid-devis-left-det > h2 {
  font-size: 40px;
  font-family: "Gilroy-Regular", sans-serif;
}

.grid-devis-left-det > p {
  margin: 0.5em 0;
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 3em;
}
.grid-devis-left-det > h2 > span {
  font-family: "Gilroy-Bold", sans-serif;
}
.grid-devis-left-det > p {
  font-family: "Gilroy-Light", sans-serif;
  line-height: 32px;
  font-size: 18px;
}
@media screen and (max-width: 600px) {
  .input-agence {
    font-size: 12px;
    width: 60%;
    padding: 0.5em 1em;
  }
  .bg-y {
    display: none;
  }
  .img-contact {
    display: none;
  }
  textarea {
    width: 90%;
    height: 90px;
  }
  .title-component {
    font-size: 20px;
  }
}
.submit {
  height: 51px;
  width: 221px;
  border-radius: 31px;
  background-color: #ffffff;
  box-shadow: 0 5px 5px 2px rgba(3, 8, 40, 0.32);
  color: #2584f9;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 20px;
}
.bordered {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-radius: 14px;
}
::placeholder {
  color: #fff;
}

.img-devis {
  width: 15rem;
  margin: 1em 0.6em;
}
.img-devis-second {
  width: 500px;
  margin-left: -180px;
}

@media only screen and (max-width: 600px) {
  .gridOnlineParent {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .gridOnlineFirst {
    width: 100%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d7e9ff;
    padding: 20px;
    text-align: left;
  }
  .input-agence {
    width: 80%;
  }
  .search-icon {
    display: none;
  }
}
.card {
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 7px 24px rgba(83, 83, 83, 0.1);
  border-radius: 11px;
  padding-bottom: 2em;
  width: 90%;
  margin: auto;
}
.top {
  margin-bottom: 2em;
  margin-top: 2em;
  height: 10px;
  width: 100%;
}
.grid-devis-mobile {
  background: #1bdebb;
  text-align: center;
  padding: 2em 0;
}
.title-typo {
  font-family: "Gilroy-Regular", sans-serif;
  padding-bottom: 0.5em;
  color: #fff;
  font-size: 20px;
  line-height: 23.44px;
}
.title-devis-mobile {
  font-family: "Gilroy-Bold", sans-serif;
}
.p-typo {
  font-family: "Gilroy-Regular", sans-serif;
  color: #fff;
  padding: 0 0.6em;
}
.img-online-mobile {
  width: 100%;
  padding: 2em 0 0.8em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
}
.img-online-mobile > img {
  height: 100%;
}
.det-online-mobile {
  text-align: center;
}
.p-subtitle,
.title-dev-mobile {
  color: #183796;
}
.p-subtitle {
  text-align: left;
}
.title-dev-mobile {
  text-align: left;
  font-size: 20px;
}

.title-dev-mobile > span {
  text-transform: uppercase;
  font-family: "Gilroy-Bold", sans-serif;
}
.dev-mobile {
  background: linear-gradient(270deg, #f7f8fc 0%, #d7e9ff 100%);
  padding-left: 0.9em;
}

.img-div {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.img-div > img {
  height: 100%;
  object-fit: fill;
  z-index: 400;
}
.push-buttom {
  height: 37px;
}
.card > h2 {
  color: #1bdebb;
  font-family: "Gilroy-Bold", sans-serif;
  font-size: 2.4em;
  padding-bottom: 1.5em;
}
.card > p {
  font-family: "Gilroy-Regular", sans-serif;
  line-height: 28px;
  color: #183796;
  text-align: center;
  padding: 0 1em;
  text-align: justify;
  text-justify: inter-word;
}
.list {
  margin-top: 1.5em;
  margin-bottom: 2em;
}
.list > h3 {
  font-family: "Gilroy-Bold", sans-serif;
  padding: 1em 0;
}
.empty {
  color: #1bdebb;
  background: rgba(27, 222, 187, 0.1);
}
.empty2 {
  color: #1bdebb;
}
.notempty {
  background: #1bdebb;
  width: 100%;
  opacity: 0.05;
  color: #1bdebb;
}
.notempty > span {
  z-index: 300;
}

.grid-assure {
  margin-top: 4em;
  margin-bottom: 7em;
}
.grid-service-parent {
  margin: 2.5em 0;
  position: relative;
}
.grid-service-img {
  position: absolute;
  left: -20px;
  bottom: -30%;
  z-index: -3;
}
.btn-btn {
  padding-top: 2em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.btn-btn > img {
  margin: 0.6em 0.5em;
  height: 100%;
  width: 78px;
}
.card-top {
  margin-top: 3em;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.center {
  height: 40px;
}
.top {
  width: 100%;
  height: 30px;
}
.no-clicked {
  background: rgba(255, 255, 255, 0.3);
}
.bordereds {
  background: #fff;
  color: #03a9ff;
}
.def {
  background: rgba(255, 255, 255, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  border-radius: 14px;

  @media screen and (max-width: 600px) {
    width: 139px;
  }
}

.card-assure {
  width: 90%;
  min-height: 460px;
  outline: none;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
}
@media screen and (max-width: 600px) {
  .card-assure {
    width: 100%;
  }
}
.card-assure > img {
  max-width: 100%;
}
.title-assure {
  color: #008dff;
  width: 100%;
  padding: 0.8em 0.5em;
  background: #edf4fa;
  font-size: 1.2em;
  font-family: "Gilroy-Bold", sans-serif;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.det-assure {
  background: #0baeff;
  height: 100%;
  flex: 1;
  color: #fff;
  padding: 0.3em 0.9em;
  border-radius: 0 0 8px 8px;
}
.logo-container {
  width: 200px;
}
.det-assure > p {
  padding: 0.5em 0;
  font-family: "Gilroy-Light", sans-serif;
  font-weight: 400;
}
.det-assure > p > span {
  font-family: "Gilroy-Bold", sans-serif;
}
.det-assure > a {
  padding: 10px 30px;
  background: #ffffff;
  box-shadow: 0px 4px 6px rgba(3, 8, 40, 0.318359);
  border-radius: 31px;
  color: #0390ff;
  margin: 0.9em 0;
  font-family: "Gilroy-Medium", sans-serif;
  display: block;
  width: 170px;
}
.btn-elem {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 100%;
}

.btn-elem > a {
  margin-top: 2em;
}
.btn-elem > a + a {
  margin-left: 1em;
}
.btn-elem > a > img {
  width: 200px;
}

@media screen and (max-width: 600px) {
  .btn-elem > a {
    display: none;
  }
  .push-buttom {
    display: none;
  }
}
.wrappers {
  max-width: 1130px;
  margin: auto;
  box-sizing: border-box;
}
.mobile {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.9em;
}
.mobile > button {
  padding: 10px 20px;
  color: #fff;
  background: linear-gradient(90deg, #13cdb0 0%, #00c9a8 100%);
  box-shadow: 0px 5px 5px rgba(3, 8, 40, 0.152797);
  border-radius: 31px;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 11px;
  margin: 0.6em 0;
}
.mobile > button + button {
  margin-left: 0.9em;
}
h3 {
  font-family: "Gilroy-Bold", sans-serif;
}
.pfirst {
  font-family: "Gilroy-Regular", sans-serif;
}

.hero-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.hero-section {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
}

.input-agence::placeholder {
  color: #000;
}
.bg-u {
  position: relative;
  height: 100%;
}

.bg-y {
  position: absolute;
  width: 94%;
  top: -20%;
  z-index: -3;
}
@media screen and (min-width: 1500px) {
  .bg-y {
    max-width: 80%;
    top: -40%;
  }
}
.ovl {
  position: absolute;
  bottom: -50%;
  z-index: -4;
}

.ov-mp {
  position: absolute;
  top: 0;
  right: -20%;
}
.rec-arrow {
  display: none;
}

.grid-contact-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #03a9ff;
  border-radius: 8px;
  color: #fff;
  margin: 0.9em 0;
}
.grid-contact-mobile-img {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grid-contact-mobile-img > img {
  height: 90%;
}
.input-contact-mobile {
  width: 90%;
  background: none;
  border-radius: 22.5px;
  border: 1px solid #fff;
  color: #fff;
  font-size: 1em;
  margin: 0.8em 0;
  height: 38.55px;
  padding-left: 2em;
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 12px;
}
.grid-input-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.grid-input-mobile-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0.9em 0;
  font-family: "Gilroy-Regular";
  font-size: 11px;
}

.input-radio-mobile {
  margin-right: 0.5em;
  height: 17px;
  width: 17px;
  border: 1px solid #ffffff;
}
.msg {
  height: 100px;
}
.grid-input-mobile-submit {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1.2em;
}
.grid-input-mobile-submit-btn {
  width: 108px;
  height: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(3, 8, 40, 0.318359);
  border-radius: 31px;
  color: #0390ff;
  margin-bottom: 1.5em;
}
.footer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(205, 215, 239, 0.3);
  mix-blend-mode: normal;
  padding-top: 2.5em;
}
.footer-mobile-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.2em 0;
}
.footer-mobile-icon > img {
  margin: 0 0.7em;
}
.footer-mobile-icon-social {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
}
.footer-mobile-icon-social > img {
  margin: 0 0.7em;
}
.first {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #183796;
  text-align: center;
  padding: 0.5em 0;
}
.first > p {
  text-decoration: underline;
  font-family: "Gilroy-Medium";
  font-weight: 900;
}
.firstList,
.secondList {
  font-size: 12px;
  text-decoration: underline;
  color: #183796;
}
.first > p > .thirdList {
  font-size: 14px;
  font-family: "Gilroy-Bold", sans-serif;
}
.hero-mobile-first {
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 70vh;
}
.hero-mobile-first-gr {
  background: linear-gradient(196.83deg, #f4f6fa 13.97%, #ebedf2 65.93%);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.img-mon {
  width: 171px;
  height: 145px;
}
.hero-mobile-first-gr > img {
  height: 100%;
  position: absolute;
  right: 0;
}

@media only screen and (max-width: 1000px) {
  .ovl {
    display: none;
  }
  .grid-online-img {
    display: none;
  }

  .wrappers {
    width: 95%;
    margin: auto;
  }
  .grid-service-img {
    display: none;
  }
}
.btn-elem > button > img {
  width: 270px;
}

@media screen and (max-width: 1227px) {
  .grid-devis-left-det-2 > img {
    height: 430px;
    position: absolute;
    top: -19%;
    right: -30%;
    z-index: 2000;
    object-fit: fill;
    overflow: hidden;
  }
  .grid-devis-left {
    padding: 3em 0;
  }
  .btn-elem > button > img {
    width: 180px;
  }
}
.section-hero {
  padding: 30px 0 70px 0;
  position: relative;
  background: #f4f6fa;
  z-index: -5;
}

.plus {
  color: #2584f9;
}
.plus > p {
  padding-top: 0.8em;
}
.conts {
  z-index: 20;
  margin: auto;
  box-sizing: border-box;
}
.directory {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 2em;
  color: #0390ff;
  line-height: 17px;
  font-family: "Gilroy-Medium", sans-serif;
  .list-nv {
    width: 100%;
    padding: 0.9em 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
}
.ar {
  width: 97px;
  height: 30px;

  margin: 0 0.6em;
  background: rgba(27, 222, 187, 0.0880888);
  border-radius: 15px;
}
.icon-bottom {
  width: 100%;
  padding: 0.5em 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.section-hero-child {
  height: 90%;
}
.section-hero-child::before {
  content: url(http://lyazidi.fikra.cloud/themes/custom/lyazidi/images/path-header.png);
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 0px;
  left: 0;
  width: 90%;
  height: 95%;
  background: #0390ff;
  border-bottom-right-radius: 40%;
}
@media screen and (max-height: 600px) {
  .section-hero-child::before {
    content: url(http://lyazidi.fikra.cloud/themes/custom/lyazidi/images/path-header.png);
    display: flex;
    align-items: flex-end;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 95%;
    background: #0390ff;
    border-bottom-right-radius: 40%;
  }
}

.section-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.section-hero-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.5em 0 0.8em;
}
.section-hero-content-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.section-hero-content-right > img {
  height: 100%;
}
.section-hero-content-left {
  padding-top: 2em;
}

.section-hero-content-left > p {
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 16px;
  color: #fff;
  padding: 0.9em 0;
  line-height: 16.41px;
  z-index: 900000;
}
.section-hero-content-left > p > span {
  font-family: "Gilro-Bold", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  z-index: 900000;
}
.section-hero-content-left > button {
  border-radius: 31px;
  color: #0390ff;
  background: #ffffff;
  box-shadow: 0px 5px 3px rgba(3, 8, 40, 0.318359);
  border-radius: 31px;
  padding: 0.9em 2em;
  margin: 0.5em 0;
  z-index: 900000;
}
.wrp-ico {
  position: fixed;
  right: 0;
  top: 35%;
  display: flex;
  flex-direction: column;
  z-index: 9000;
}
.icon-box {
  background: #fff;
  padding: 0.5rem 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.245867);
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0;
  cursor: pointer;
  transition: all 0.5s;
  transform: translateX(120px);
  width: 160px;
  z-index: 9000;
  &:hover {
    transform: translateX(20px);
  }
}
.icon-box > img {
  height: 100%;
}
.header-mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-mobile-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.header-mobile-left > img {
  height: 100%;
  padding: 0.5em 0.8em;
}
.header-mobile-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}
.header-mobile-right p {
  font-size: 9px;
  color: #2584f9;
  text-transform: uppercase;
}

.header-mobile-right-menu {
  cursor: pointer;
  margin-right: 3em;
}
.header-mobile-right-menu > img {
  height: 100%;
}

.icn-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.6em;
}
.top-drawer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.6em 2em;
  border-left: 7px solid #1bdebb;
}
.top-drawer > p {
  font-size: 14px;
}
.particulier {
  padding: 0.3em 0.9em;
  margin: 0 0.8em;
  background: rgba(27, 222, 187, 0.0880888);
  box-sizing: border-box;
  border-radius: 15px;
  color: #0390ff;
}
.entreprise {
  padding: 0.3em 0.9em;
  background: rgba(27, 222, 187, 0.0880888);
  box-sizing: border-box;
  border-radius: 15px;
  color: #0390ff;
}
.top-drawer-right {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.header-mobile {
  width: 100%;
}
.header-child {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #2584f9;
}
.header-child > p {
  font-size: 12px;
}
.logo-mobile > img {
  padding: 0 0.6em;
  cursor: pointer;
}

.menu-it {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}
.menu-menu {
  padding: 20px;
  border-left: 1px solid #0390ff;
  cursor: pointer;
}
.menu-lock {
  margin-right: 0.9em;
  cursor: pointer;
}
.menu-lock > img {
  height: 200%;
}
.menu-lock > p {
  font-size: 11px;
}
.menu-menu > p {
  font-size: 11px;
}

.contact-card {
  width: 90%;
  margin: 0.5em auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 9px;
  padding: 0.3em 0;
  cursor: pointer;
}
.contact-card > p {
  padding-top: 0.5em;
  font-size: 15px;
  font-family: "Gilroy-Medium", sans-serif;
}
.img-cont {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-cont > img {
  height: 65%;
  object-fit: fill;
}
@media only screen and (max-width: 590px) {
  .section-hero-content {
    display: flex;
    flex-direction: row;
  }

  .section-hero-content-right > img {
    width: 130%;
    height: 100%;
  }
}
@media only screen and (max-width: 1070px) {
  .grid-online-text {
    padding: 2em 0;
  }
  .btn-element > button {
    padding: 0.9em 1em;
  }
}

@media screen and (max-width: 600px) {
  .contact-card > p {
    text-align: center;
  }
  .section-hero-content-left > p {
    width: 160px;
  }
}

.info-mobile {
  position: fixed;
  bottom: 0;
  z-index: 40000;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 5px 5px 0px 0px;

  border-top: 1px solid #2584f9;
}
.info-mobile > div {
  max-height: 90px;
  padding: 20px;
  background-color: #fff;
  border-left: 1px solid #2584f9;
  width: calc(100% / 2);
}
.mail {
  border-top-left-radius: 5px;
}
v .location {
  border-right: 1px solid #2584f9;
  border-top-right-radius: 5px;
  cursor: pointer;
}
.img-box-m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-box-m > a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #2584f9;
}
.img-box-m > img {
  height: 100%;
  object-fit: fill;
}
.info-mobile > div > img {
  height: 100%;
}
.deflt {
  border: 1px solid #1bdebb;
  padding: 0.3em 0.9em;
  background: rgba(27, 222, 187, 0.0880888);
  box-sizing: border-box;
  border-radius: 15px;
  color: #0390ff;
  margin: 0 0.8em;
}
.he-container {
  position: fixed;
  z-index: 9000;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}
.bc {
  background-color: #fff;
  z-index: 9500;
}
.menu-bars {
  z-index: 10;
  display: inline;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 30px;
  height: 3px;
  background-color: #0390ff;
  margin: 4px 0;
  transition: 0.4s;
}

/* Rotate first bar */
.changeOne {
  transform: rotate(-45deg) translate(-8px, 0.5px);
}

.changeTwo {
  opacity: 0;
}
.changeThree {
  transform: rotate(45deg) translate(-8px, -4px);
}
.spinner {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #0baeff;
  border-color: #0baeff transparent #0baeff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.dotsss {
  position: absolute;
}
.nbn {
  padding: 0.3em 1.5em;
}
.MuiTypography-body1 {
  text-align: center;
  padding: 0.5em 0.9em;
}
.green {
  color: green;
  text-align: center;
}
.red {
  color: red;
  text-align: center;
}
.grid-contct {
  display: flex;
}
.icon__link {
  display: flex;
}
.icon__title {
  padding: 0.2em 0.5em;
  color: #2584f9;
  font-size: 0.9em;
}
.link_side,
.link_side > div {
  z-index: 9000;
}
.link_side > * {
  z-index: 9000;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 0.9rem 0;
  max-width: 1200px;
  margin: 3rem auto;
}
.produit {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  padding: 0.9rem 2rem;
  cursor: pointer;
  width: 90%;
  @media screen and (min-width: 1050px) {
    height: 23rem;
  }
  box-shadow: 2px 6px 17px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  background-color: #fff;
  &__info {
    display: flex;
    text-align: center;
    flex-direction: column;
    margin-left: 0.7rem;
  }
  &__svg {
    height: 50px;
    color: #2584f9;
    margin-top: 0.4rem;
  }
  &__title {
    color: #2584f9;
    font-size: 20px;
    font-family: "Gilroy-Bold", sans-serif;
    margin-bottom: 0.8rem;
  }
  &__paragraph {
    color: #666666;
    font-family: "Gilroy-Regular", sans-serif;
    font-size: 16px;
    text-align: justify;
    text-justify: inter-word;
  }
  &__span {
    display: block;
    margin: 0.3rem 0;
  }
}
.desktop-version {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin: 3rem auto;
  max-width: 1200px;
}
